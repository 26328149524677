import React from "react";
//import Stars from "../components/stars";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import Img from "gatsby-image";
//import moment from 'moment';

export default ({title, name, header, source, fluid, children}) => (
  <Card className='cg-reviewCard'>
    {fluid &&
      <Img fluid={fluid} className='card-img-top'></Img>
    }
    {header &&
    <Card.Header as="h5">{header}</Card.Header>
    }
    <Card.Body>
      {title &&
      <Card.Title>{title}</Card.Title>
      }
      {/*
      <Card.Subtitle>
        <Stars className='rating'/>
      </Card.Subtitle>
      */}
      <Card.Text>
          <FontAwesomeIcon icon={faQuoteLeft} className='quoteChar'/>
          <span className='quoteText'> {children} </span>
          <span className='attribution'>
            ~&nbsp;{name}
          </span> 
          {/* {date && <span className='ratingDate'>{moment(date, "M/D/YYYY").fromNow()}</span>} */}
          {/* {source  && <span className='ratingSource'> via {source}</span>} */}
      </Card.Text>
    </Card.Body>
  </Card>
);

