import React from "react";
import AboveFold from "../components/aboveFold";
import ContactForm from "../components/contactForm";
import Hr from "../components/hr";
import Name from "../components/name";
import Layout from "../components/layout";
import ReviewCard from "../components/reviewCard";
import SectionHeading from "../components/sectionHeading";
import { 
  Container, 
  Row, 
  Col, 
  Card,
} from "react-bootstrap";
import { 
  useStaticQuery, 
  graphql, 
  Link,
} from "gatsby";
import Img from "gatsby-image";


const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          tagline
          ctaText
          ctaLink
          lmText
          lmLink
        }
      },
      background: file(relativePath: { eq: "usp_9_working.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      problem1: file(relativePath: { eq: "redshed_1.JPG" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      problem2: file(relativePath: { eq: "storage-warehouse-dollar1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      problem3: file(relativePath: { eq: "usp_3_fence_1_txt_b.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ad: file(relativePath: { eq: "usp_2_8x12_ad_close_2s.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature1: file(relativePath: { eq: "fealy_10x12-by-playground.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature2: file(relativePath: { eq: "fealy_8x10_garden_3.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature3: file(relativePath: { eq: "usp_6_front_b.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      review1: file(relativePath: { eq: "usp_4_4x8_royce_b.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // jesse_10x16.png"
  
  return (
  <Layout>
  
    <AboveFold 
      canonical=""
      title="Sheds for the Utica, NY and Rome, NY area"
      description={data.site.siteMetadata.tagline}
      ctaText={data.site.siteMetadata.ctaText}
      ctaLink={data.site.siteMetadata.ctaLink}
      lmText={data.site.siteMetadata.lmText}
      lmLink={data.site.siteMetadata.lmLink}
      img={data.background.childImageSharp.fluid}
      >
        <h1><Name/></h1>
        {/* <div className="cg-tagline">{data.site.siteMetadata.tagline}</div> */}
        {/* <div className="cg-problem">Kick&nbsp;mini-storage&nbsp;fees. Boost&nbsp;property&nbsp;value.</div> */}
        <div className="cg-problem">{data.site.siteMetadata.tagline}</div>
      </AboveFold>
      
    <Hr/>
    
    <main id='main'>
      <div className='cg-sectionShaded'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>Storage Problems?</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.problem1.childImageSharp.fluid} className='cg-picture'/>
            </Col>
            <Col>
              <p>Need to replace an old shed?</p>
              {/*<p>It's too bad: if the right materials had been used, this shed might be around a while longer.</p>*/}
            </Col>
          </Row>
          <Row>
            <Col style={{display:'flex',alignItems:'center'}}>
              <p>Want to avoid mini-storage fees?</p>
            </Col>
            <Col>
              <Img fluid={data.problem2.childImageSharp.fluid} className='cg-picture'/>
            </Col>
          </Row>
          <Row>
            <Col style={{textAlign:'center'}}>
              <p>Need a shed behind a fence?</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.problem3.childImageSharp.fluid} className='cg-picture'/>
            </Col>
          </Row>
          <Row>
            <Col style={{textAlign:'center'}}>
              <p>We have a great solution...</p>
            </Col>
          </Row>
        </Container>
      </div>
      
      <Hr/>
      
      <div className='cg-sectionLight'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading subtitle="No factory. No delivery truck.">We Build in Your Backyard</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p>
                To <strong>solve your storage problems</strong> and also <strong>add value</strong> to your
                property, we can build a shed in your backyard.
              </p>
            </Col>
            <Col sm>
              <Img fluid={data.feature2.childImageSharp.fluid} className='cg-picture'/>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <Img fluid={data.ad.childImageSharp.fluid} className='cg-picture'/>
            </Col>
            <Col sm>
              <p>
                We're a father and son team working to build great sheds for our neighbors.
              </p>
              <p>
                Our focus is to efficiently make <strong>durable sheds on-site</strong>. 
                This gives you the best shed at a good price.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <a className="cg-secondary btn btn-secondary" href={data.site.siteMetadata.lmLink}>{data.site.siteMetadata.lmText}</a>
            </Col>
            <Col>
            <Link className="cg-dark btn btn-primary" to={data.site.siteMetadata.ctaLink} >{data.site.siteMetadata.ctaText}</Link>
            </Col>
          </Row>
        </Container>
      </div>
      
      
      <Hr/>
    
      <div className='cg-sectionShaded'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>What to Look For in a Shed</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.feature1.childImageSharp.fluid} className='cg-picture'/>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>A good shed has these features:</p>
              
              <strong>Weather proof PVC trim</strong>
              <ul>
                <li>PVC trim will last much longer than pine and never needs painted.</li>
                <li>(Pine trim is the first thing to rot on a shed.)</li>
              </ul>
              
              <strong>Heavy duty floor</strong>
              <ul>
                <li>Look for ¾” treated plywood flooring.</li>
                <li>(Don’t buy a shed with OSB flooring - it can swell with moisture.)</li>
                <li>Some companies use 2x4s for joists. Be sure your shed has 2x6 treated floor joists to bear load and last.</li>
                <li>(Often shed "kits" don't include a floor at all.)</li>
              </ul>
              
              <strong>Off the ground</strong>
              <ul>
                <li>Install your shed on concrete blocks</li>
                <li>Ground contact will cause premature rot</li>
              </ul>
              
              <strong>Wall construction</strong>
              <ul>
                <li> Solid walls have 2x4 studs with double top plates</li>
                <li> Cheap sheds use thin lumber (2x3) spaced far apart</li>
              </ul>
              
              <strong>Efficient design</strong>
              <div>Avoid these unnecessary expenses in an <em>uninsulated</em> shed:</div>
              <ul>
                <li>Dual pane windows: Single pane are fine for a shed.</li>
                <li>Ridge beams: Trusses work well and cost less. </li>
                <li>Soffit: Again, unnecessary on most sheds.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <p><strong>There's More!</strong> Get our <a href={data.site.siteMetadata.lmLink} className="cg-linkDark">{data.site.siteMetadata.lmText}</a> for a list of 28 items to look for when shopping for your shed.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <a className="cg-secondary btn btn-secondary" href={data.site.siteMetadata.lmLink}>{data.site.siteMetadata.lmText}</a>
            </Col>
            <Col>
            <Link className="cg-dark btn btn-primary" to={data.site.siteMetadata.ctaLink} >{data.site.siteMetadata.ctaText}</Link>
            </Col>
          </Row>
        </Container>
      </div>
      
      <hr/>
      
      <div className='cg-sectionDark' id='advantages'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading subtitle="What Sets Us Apart" darkbg><Name/> Advantage</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Service</Card.Title>
                  <Card.Text>
                    <ul>
                      <li>Our sheds come with a <strong>five year warranty</strong>*</li>
                      <li>Easily <a href="/schedule" className="cg-linkDark">schedule</a> your shed build date online.</li>
                      <li>There is <strong>no deposit required</strong> to book your shed installation. <small>(A deposit is required on some custom items like a custom paint colors or special order roll up doors.)</small></li>
                      <li><strong>Flexible payment</strong>: PayPal, Venmo, Visa, Mastercard, Discover, cash or check. <small>(Prices listed are cash or check; a transaction fee may apply to other payment methods)</small></li>
                      <li>Custom built means more <a href="https://info.uticashedpros.com/f/colors" className="cg-linkDark">options</a> for you: your choice of window and door placement; you choose shingle color; we can paint to match your house</li>
                    </ul>
                  </Card.Text>
                  
                  <div style={{width:"100%",display:"flex", justifyContent:"center"}}>
                    <Link to="/schedule" className="cg-secondary btn btn-secondary">Book Online</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Quality</Card.Title>
                  <Card.Text>
                    <ul>
                      <li>No Maintenance <strong>PVC Trim</strong> - never needs re-painting. (<em>Unlike PVC, pine trim rots</em>)</li>
                      <li><strong>Heavy duty floors</strong> (treated plywood with 2x6 joists 16 OC on raised concrete blocks)</li>
                      <li>Solid walls (2x4 studs)</li>
                      <li>Aluminum drip edge (will never rust)</li>
                      <li>30 year architectural shingles (not 3-tab)</li>
                    </ul>
                  </Card.Text>
                  
                  <div style={{width:"100%",display:"flex", justifyContent:"center"}}>
                    <Link to="/options"  className="cg-secondary btn btn-secondary">See Extra Options</Link>
                    <Link to="/features" className="cg-secondary btn btn-secondary">See Standard Features</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Price</Card.Title>
                  <Card.Text>
                    <ul>
                      <li><strong>You don't need to stain/paint</strong> (price includes standard tan primer, which most people leave)</li>
                      <li>No extra sales tax. It's included - <strong>not added on afterwards</strong></li>
                      <li>No delivery fee (free onsite installation)</li>
                      <li>Not over designed &#8594; lower material costs</li>
                      <li>Owner operated &#8594; lower overhead costs</li>
                      <li>Built onsite &#8594; no storage or delivery costs</li>
                    </ul>
                    <small>If you're comparing our prices to other shed builders, be sure to add up the things you'll need that <strong>they don't include in the base price</strong>: sales tax, painting / staining, "upgrade" to treated floor joists, ¾" floor sheathing, site leveling, gable vents, etc.</small>
                  </Card.Text>
                  
                  <div style={{width:"100%",display:"flex", justifyContent:"center"}}>
                    <Card.Link href="/pricing" className="cg-secondary btn btn-secondary">See Pricing</Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col style={{textAlign:'center'}}>
              <Link className="cg-dark btn btn-primary" to={data.site.siteMetadata.ctaLink} >{data.site.siteMetadata.ctaText}</Link>
            </Col>
          </Row>
        </Container>
      </div>
      
      <hr/>
      
      
    
      <div className='cg-sectionLight'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>* 5 Year Warranty</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.feature3.childImageSharp.fluid} className='cg-picture'/>
            </Col>
          </Row>
          <Row>
            <Col>
              We're so confident in the quality of our sheds, you get a <strong>5 Year Warranty</strong>
              <ul>
                <li>Our 5 year warranty covers construction defects.</li>
                <li>Of course, it does not include damage from accidents, fire, misuse, trees, tree limbs, and does not include moving the shed after it is built.</li>
                <li>If there was something not done right, we'll come back to your house and <strong>fix it free</strong> (materials and labor).</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      
      <hr/>
      
      
    
      <div className='cg-sectionShaded'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>What Customers Say</SectionHeading>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <ReviewCard title='Recommended' name='Evelyn' style={{maxWidth:'30rem'}}>
                I needed a strong shed to store our bikes. Ben constructed a solid and attractive shed quickly and professionally. It was exactly and better than what I had hoped for, and all for a great price. I recommend him to anyone who needs a storage shed.
              </ReviewCard>
            </Col>
            <Col sm>
              <ReviewCard name='Royce near Utica, NY' style={{maxWidth:'30rem'}} fluid={data.review1.childImageSharp.fluid}>
                A happy homeowner
              </ReviewCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReviewCard title='Pleased' name='Dawn in Rome, NY' style={{maxWidth:'30rem'}}>
                I would highly recommend Utica Shed Pros! Ben and Aaron are very hardworking and meticulous in the work they did building my 8x12 shed. I could not be more pleased. I also hired them to demolish my old shed and they removed every bit of debris. A fantastic job, this is a very solid shed and no doubt will last for many years. You guys are great! I even ordered flower boxes and they said "no problem". Thank you again
              </ReviewCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReviewCard title='Awesome job' name='Dale in Utica, NY' style={{maxWidth:'30rem'}}>
                Ben and Aaron did an awesome job with building our shed. I was having a difficult finding a prefabricated shed that I could get into my fenced in backyard so I was grateful that Utica Shed Pros build onsite, and I am glad I went with them! Now that my shed is up it is so much more solid and secure than some of the premade sheds and kits I had looked at. From the prebuild planning right thru to the completion of our shed Ben and Aaron were extremely clean, quiet, professional, quick, and efficient. It's been up for about a month now and still extremely happy! No surprises or shortcuts taken with their work! I highly recommend!
              </ReviewCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <a className="cg-secondary btn btn-secondary" href="/pricing">See Pricing</a>
            </Col>
            <Col>
              <Link className="cg-dark btn btn-primary" to={data.site.siteMetadata.ctaLink} >{data.site.siteMetadata.ctaText}</Link>
            </Col>
          </Row>
        </Container>
      </div>
      
      <Hr/>
      
    </main>
    
    <ContactForm/>
  </Layout>
)};

export default Home;
